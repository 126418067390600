<template>
    <div class="git-logs-wrapper">
        <div v-if="bitbucket && bitbucket.length">
            <div v-for="(b, idx) in bitbucket" :key="idx">
                <Card class="section-title">
                    {{ b.name }}
                </Card>
                <Table
                    :columns="tblColumns"
                    :data="b.commits ? b.commits.data : b.commits"
                    size="small"
                    :loading="isLoading"
                    height="450"
                />
                <Page
                    v-if="b.commits ? b.commits.totalCount > 10 : b.commits"
                    :total="b.commits ? b.commits.totalCount : b.commits"
                    :current="b.commits.currentPage"
                    :page-size="10"
                    class="pagination md-margin-bottom"
                    @on-change="handleOnpageChanged($event, b.name)"
                />
            </div>
        </div>
        <p v-else class="text-center">No Data</p>
    </div>
</template>

<script>
import moment from 'moment';
import { getBitbucketCommits } from '@/services/bitbuckets.services';

export default {
    props: {
        bitbucket: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            page: 1,
            totalCount: null,
            isLoading: false,
            tempTableLoading: null,
            tblColumns: [
                {
                    title: 'Author',
                    key: 'author',
                    width: 200,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.commitDetails.author.raw
                                ? params.row.commitDetails.author.raw.match(/<([^)]+)>/)[1]
                                : ''
                        );
                    },
                },
                {
                    title: 'Commit #',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', params.row.commitDetails.short_hash ? params.row.commitDetails.short_hash : '');
                    },
                },
                {
                    title: 'Message',
                    minWidth: 250,
                    render: (h, params) => {
                        return h('div', params.row.commitDetails.message ? params.row.commitDetails.message : '');
                    },
                },
                {
                    title: 'Timestamp',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.createdAt ? moment(params.row.createdAt).format('DD-MMM-YYYY') : '');
                    },
                },
            ],
            tblData: [],
        };
    },
    async mounted() {
        this.isLoading = true;
        const bitbucket = this.bitbucket.map(async (data) => {
            const commits = await this.handleGetBitbucketCommits({
                'commitDetails.repository.uuid': data.id,
            });
            return {
                ...data,
                commits,
            };
        });
        this.bitbucket = await Promise.all(bitbucket);
        this.isLoading = false;
    },
    methods: {
        async handleGetBitbucketCommits(filter = {}, page, totalCount) {
            return await getBitbucketCommits(filter, page, totalCount)
                .then((res) => {
                    let tempData = {
                        data: res.data.data,
                        totalCount: res.data.totalCount,
                        currentPage: res.data.page,
                    };
                    return tempData;
                })
                .catch(() => {
                    this.$Message.error('Failed to get bitbucket commits');
                });
        },

        async handleOnpageChanged(page, name) {
            this.isLoading = true;
            const bitbucket = this.bitbucket.map(async (data) => {
                if (data.name == name) {
                    const commits = await this.handleGetBitbucketCommits(
                        { 'commitDetails.repository.uuid': data.id },
                        page
                    );
                    return {
                        ...data,
                        commits,
                        currentPage: page,
                    };
                } else {
                    const commits = await this.handleGetBitbucketCommits(
                        { 'commitDetails.repository.uuid': data.id },
                        data.currentPage
                    );
                    return {
                        ...data,
                        commits,
                    };
                }
            });
            this.bitbucket = await Promise.all(bitbucket);
            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
</style>
