<template>
    <div class="logs-wrapper">
        <div class="logs-filter md-margin-bottom">
            <Button
                size="large"
                class="md-margin-right"
                :class="logType == 'git' ? 'pt-btn-2' : ''"
                @click="logType = 'git'"
            >
                <b>GIT </b>
            </Button>
            <Button
                size="large"
                class="md-margin-right"
                :class="logType == 'slack' ? 'pt-btn-2' : ''"
                @click="logType = 'slack'"
            >
                <b>SLACK </b>
            </Button>
            <Button
                size="large"
                class="md-margin-right"
                :class="logType == 'task' ? 'pt-btn-2' : ''"
                @click="logType = 'task'"
            >
                <b>TASKS </b>
            </Button>
            <Button
                size="large"
                class="md-margin-right"
                :class="logType == 'emails' ? 'pt-btn-2' : ''"
                @click="logType = 'emails'"
            >
                <b>EMAILS </b>
            </Button>
        </div>
        <div class="d-flex-center lg-margin-bottom">
            <p class="md-margin-right">Project</p>
            <Select
                v-model="selectedProjectId"
                filterable
                clearable
                :loading="isLoading"
                class="md-margin-right"
                style="max-width: 300px"
                @on-change="handleSelectProjectChange"
            >
                <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                    {{ item.label }}
                </Option>
            </Select>
            <Button class="pt-btn-1" style="width: 100px" @click="handleOnFilter(selectedProjectId)"> Filter </Button>
        </div>
        <div v-if="selectedProject && showTable && isFilterClick" class="md-margin-top">
            <Gitlogs v-if="logType == 'git'" ref="Gitlogs" :bitbucket="selectedProject.bitbucket" />
            <SlackLogs
                v-if="logType == 'slack'"
                ref="SlackLogs"
                :slacks="selectedProject.slack"
                :project="selectedProject"
            />
            <TasksLogs v-if="logType == 'task'" ref="TasksLogs" :project="selectedProject" />
            <EmailLogs v-if="logType == 'emails'" ref="EmailLogs" :project="selectedProject" />
        </div>
    </div>
</template>

<script>
import { getProjects, getProjectsById } from '@/services/projects.services';
import Gitlogs from '@/components/Logs/GitLogs';
import TasksLogs from '@/components/Logs/TasksLogs';
import SlackLogs from '@/components/Logs/SlackLogs';
import EmailLogs from '@/components/Logs/EmailLogs';

export default {
    components: {
        Gitlogs,
        SlackLogs,
        TasksLogs,
        EmailLogs,
    },

    data() {
        return {
            isLoading: false,
            projectsList: [],
            logType: 'git',
            selectedProjectId: null,
            selectedProject: null,
            showTable: false,
            isFilterClick: false,
        };
    },

    mounted() {
        this.handleGetProjects();
    },

    methods: {
        async handleGetProjects() {
            this.isLoading = true;
            this.projectsList = [];
            let allData = [];
            allData = await this.handleGetAllProjects();

            if (allData) {
                const parsedAllData = allData.reduce((acc, obj) => {
                    if (obj.isActive && obj.isArchived === false) {
                        acc.push({
                            value: obj._id,
                            label: obj.name,
                        });
                    }
                    return acc;
                }, []);

                this.projectsList = await Promise.all(parsedAllData);
                this.projectsList = this.projectsList.sort((a, b) => (a.label > b.label ? 1 : -1));
            }

            // await this.handleGetProjectById(this.projectsList[0].value)
            this.isLoading = false;
        },

        handleGetAllProjects() {
            return getProjects()
                .then((res) => {
                    if (res.data.length) {
                        return res.data;
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get all projects');
                });
        },

        handleSelectProjectChange(val) {
            this.isFilterClick = false;
            if (val) this.handleGetProjectById(val);
        },

        handleGetProjectById(id) {
            this.selectedProject = null;
            getProjectsById(id)
                .then((res) => {
                    if (res.data) this.selectedProject = res.data;
                })
                .catch(() => {
                    this.$Message.error('Failed to get project by id');
                });
        },

        handleOnFilter(projId) {
            this.isFilterClick = true;
            this.selectedProjectId = projId;
            this.showTable = true;
            this.handleGetProjectById(projId);
        },
    },
};
</script>
<style lang="scss">
@media screen and (max-width: 767px) {
    .logs-filter {
        display: grid;
        grid-template-columns: 2fr 2fr;
    }
}
</style>
