<template>
    <div class="email-logs-wrapper">
        <div v-if="project && Object.keys(project).length">
            <Card class="section-title"> WEEKLY EMAILS </Card>
            <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" height="450" />
            <Page
                v-if="totalCount > 10"
                :total="totalCount"
                :current="page"
                :page-size="10"
                class="pagination md-margin-bottom"
                @on-change="handleOnpageChanged"
            />
        </div>
        <p v-else class="text-center">No Data</p>
        <EmailDetails ref="EmailDetails" />
    </div>
</template>

<script>
import moment from 'moment';
import { getWeeklyEmails } from '@/services/weekly-emails.services';
import EmailDetails from '@/components/Logs/EmailDetails';

export default {
    components: {
        EmailDetails,
    },
    props: {
        project: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            page: 1,
            totalCount: null,
            isLoading: false,
            tblColumns: [
                {
                    title: 'Date',
                    key: 'createdAt',
                    sortable: true,
                    sortType: 'desc',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.createdAt ? moment(params.row.createdAt).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'Type',
                    key: 'type',
                    width: 90,
                },
                {
                    title: 'Sender',
                    key: 'sender',
                    width: 200,
                },
                {
                    title: 'Subject Line',
                    key: 'subject',
                    minWidth: 300,
                },
                {
                    title: 'Words #',
                    key: 'wordCount',
                    align: 'center',
                    width: 100,
                },
                {
                    title: 'Tasks #',
                    key: 'numOfTasks',
                    align: 'center',
                    width: 100,
                },
                {
                    title: 'Tracker Hours #',
                    key: 'trackerHours',
                    align: 'center',
                    width: 130,
                },
                {
                    title: 'Est. Hours #',
                    key: 'estimatedHours',
                    align: 'center',
                    width: 120,
                },
                {
                    title: 'Rated',
                    key: 'rated',
                    align: 'center',
                    width: 100,
                },
                {
                    title: 'Actions',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-eye',
                                    size: '20',
                                },
                                on: {
                                    click: () => {
                                        this.showWeeklyEmailDetails(params.row);
                                    },
                                },
                            }),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },
    async mounted() {
        this.isLoading = true;
        const tblEmailsData = await this.handleGetWeeklyEmails(this.project._id);
        this.tblData = await Promise.all(tblEmailsData);
        this.isLoading = false;
    },
    methods: {
        handleGetWeeklyEmails(id, page, totalCount) {
            this.isLoading = true;
            return getWeeklyEmails(id, page, totalCount)
                .then((res) => {
                    this.isLoading = false;
                    this.totalCount = res.data.totalCount;
                    return res.data.data;
                })
                .catch(() => {
                    this.$Message.error('Failed to get emails by project');
                    this.isLoading = false;
                });
        },

        async handleOnpageChanged(page) {
            this.isLoading = true;
            const tblEmailsData = await this.handleGetWeeklyEmails(this.project._id, page);
            this.tblData = await Promise.all(tblEmailsData);
            this.isLoading = false;
        },

        async showWeeklyEmailDetails(data) {
            if (data) {
                this.$refs.EmailDetails.openModal(data);
            } else {
                this.$Message.info('No email details yet');
            }
        },
    },
    // watch: {
    //   project(val) {
    //     if(val) this.handleGetWeeklyEmails(val)
    //   }
    // }
};
</script>

<style></style>
