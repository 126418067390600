<template>
    <Modal
        v-model="showModal"
        draggable
        sticky
        title=" "
        footer-hide
        :before-close="handleClose"
        width="900"
        class-name="task-stories-modal-wrapper"
    >
        <vue-json-pretty :path="'res'" :data="stories" />
    </Modal>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        VueJsonPretty,
    },
    data() {
        return {
            showModal: false,
            stories: null,
        };
    },
    methods: {
        openModal(data) {
            this.showModal = true;
            this.stories = data;
        },
        handleClose() {
            this.showModal = false;
            this.stories = null;
        },
    },
};
</script>

<style lang="scss">
.task-stories-modal-wrapper {
    .ivu-modal {
        .ivu-modal-content {
            .ivu-modal-body {
                max-height: 600px;
                overflow-y: scroll;
            }
        }
    }
}
</style>
