<template>
    <Modal
        v-model="showModal"
        draggable
        sticky
        title=" "
        footer-hide
        :before-close="handleClose"
        width="900"
        class-name="email-details-modal-wrapper remove-padding"
    >
        <div slot="header" style="color: #fff; text-align: left">
            Add Rating >
            <Rate />
            <Button class="pt-btn-1"> Submit </Button>
        </div>
        <Card class="pt-custom-card email-details-wrapper marginBtm">
            <p class="sm-margin-bottom"><b>Date:</b> {{ dateFormatted }}</p>
            <p class="sm-margin-bottom"><b>From:</b> {{ emailDetails.sender }}</p>
            <p class="sm-margin-bottom">
                <b>To:</b
                ><span v-for="(item, index) in emailDetails.receipient" :key="index"
                    ><span v-if="index != 0">, </span>
                    {{ `${item.email}` }}
                </span>
            </p>
            <p class="sm-margin-bottom">
                <b>Cc:</b
                ><span v-for="(item, index) in emailDetails.cc" :key="index"
                    ><span v-if="index != 0">, </span>
                    {{ `${item.email}` }}
                </span>
            </p>
            <p class="sm-margin-bottom"><b>Subject line:</b> {{ emailDetails.subject }}</p>
            <p class="sm-margin-bottom"><b>Rating:</b> <Rate /></p>
        </Card>
        <!-- eslint-disable vue/no-v-html -->
        <div style="height: 350px; overflow-y: auto" v-html="emailDetails.emailBody" />
        <!--eslint-enable-->
    </Modal>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            showModal: false,
            emailDetails: [],
            dateFormatted: null,
        };
    },
    methods: {
        openModal(data) {
            this.showModal = true;
            this.emailDetails = data;
            this.dateFormatted = moment(this.emailDetails.dateSent).format('DD-MMM-YYYY HH:mm:ss A');
        },
        handleClose() {
            this.showModal = false;
        },
    },
};
</script>

<style>
.remove-padding .ivu-modal-body {
    padding: 16px !important;
}
.marginBtm {
    margin-bottom: 15px;
}
</style>
