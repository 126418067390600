<template>
    <div>
        <Card class="pt-custom-card email-details-wrapper">
            <div slot="title">
                Add Rating >
                <Rate />
                <Button class="pt-btn-1"> Submit </Button>
            </div>
            <p class="sm-margin-bottom"><b>Date:</b> 16-Mar-2022</p>
            <p class="sm-margin-bottom"><b>From:</b> sgygli@startechup.com</p>
            <p class="sm-margin-bottom">
                <b>To:</b> nolwenn.guillemot@inventhys.com, dbonnefoux@startechup.com, richard.phan@inventhys.com
            </p>
            <p class="sm-margin-bottom"><b>Cc:</b> projectupdates@startechup.com</p>
            <p class="sm-margin-bottom"><b>Subject line:</b>End of Week Wrapup > Project Tracker > Mar 16</p>
            <p class="sm-margin-bottom"><b>Rating:</b> <Rate /></p>
        </Card>
    </div>
</template>

<script>
export default {};
</script>
<style lang="scss">
.email-details-wrapper {
    .ivu-card-head {
        text-align: left !important;
        color: #ffffff;
    }
}
</style>
