<template>
    <Card class="pt-custom-card">
        <p slot="title">END OF WEEK WRAPUP</p>
        <p>
            Lorem ipsum dolor sit amet. Eum sunt facere quo eveniet voluptate vel cumque corrupti qui optio unde ea
            ratione atque id rerum rerum est fugit reprehenderit. Ex sint porro aut repellendus facere et distinctio
            laudantium et fuga esse cum sunt eligendi aut nisi quisquam in nihil nobis. Aut corrupti consequatur ad
            praesentium blanditiis qui molestiae culpa et nihil aspernatur At repellendus quidem nam dolorem accusamus
            aut ducimus odio.
        </p>
        <br />
        <ul class="md-margin-left">
            <li>test</li>
            <li>testing</li>
            <li>tested</li>
        </ul>
        <br />
        <p>Cheers,</p>
        <br />
        <p class="text-right">Sebastien Gygli</p>
        <p class="text-right">sgygli@startechup.com</p>
    </Card>
</template>

<script>
export default {};
</script>

<style></style>
