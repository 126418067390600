<template>
    <div class="git-logs-wrapper">
        <ButtonGroup v-if="project && Object.keys(project).length" class="sm-margin-bottom">
            <Button :class="selectedTask == 'asana' ? 'pt-btn-2' : ''" @click="handleOnChangeTask('asana')">
                Asana
            </Button>
            <Button :class="selectedTask == 'trello' ? 'pt-btn-2' : ''" @click="handleOnChangeTask('trello')">
                Trello
            </Button>
            <Button :class="selectedTask == 'jira' ? 'pt-btn-2' : ''" @click="handleOnChangeTask('jira')">
                Jira
            </Button>
        </ButtonGroup>
        <div v-if="tasks && tasks.length">
            <div v-for="(t, idx) in tasks" :key="idx">
                <Card class="section-title">
                    {{ t.label }}
                </Card>
                <Table :columns="tableColumns" :data="t.tasks.data" size="small" :loading="isLoading" height="450" />
                <Page
                    v-if="t.tasks.totalCount > 10"
                    class="pagination md-margin-bottom"
                    :total="t.tasks.totalCount"
                    :current="t.tasks.currentPage"
                    :page-size="10"
                    @on-change="handleOnpageChanged($event, t.id)"
                />
            </div>
        </div>
        <p v-else class="text-center">No Data</p>
        <TasksStoriesModal ref="TasksStoriesModal" />
    </div>
</template>

<script>
import moment from 'moment';
import { getPaginatiedAsanaTasks, getAsanaTaskStories } from '@/services/asana.services';
import { getTrelloCardsListById } from '@/services/trello.services';
import { getPaginatedJiraIssues } from '@/services/jira.services';
import TasksStoriesModal from '@/components/Logs/TaskStoriesModal';

export default {
    components: {
        TasksStoriesModal,
    },
    props: {
        project: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            page: 1,
            totalCount: null,
            isLoading: false,
            tableColumns: [],
            asanaTblColumns: [
                {
                    title: 'Task #',
                    key: 'gid',
                    width: 150,
                },
                {
                    title: 'Task Title',
                    key: 'name',
                    minWidth: 300,
                },
                {
                    title: 'Due Date',
                    width: 150,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.details && params.row.details.due_on
                                ? moment(params.row.details.due_on).format('DD-MMM-YYYY')
                                : ''
                        );
                    },
                },
                {
                    title: 'Hours #',
                    key: 'timestamp',
                    width: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.details && params.row.details.custom_fields
                                ? params.row.details.custom_fields[0].number_value
                                : ''
                        );
                    },
                },
                {
                    title: 'Status',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.section ? params.row.section.name : '');
                    },
                },
                {
                    title: 'On-going',
                    key: 'ongoing',
                    width: 150,
                    render: (h, params) => {
                        let valueData = '';
                        if (params.row.sectionDetails) {
                            Object.entries(params.row.sectionDetails).forEach(([key, value]) => {
                                if (key.includes('Going')) {
                                    valueData = value;
                                }
                            });
                            return h('div', valueData ? moment(valueData).format('DD-MMM-YYYY') : '');
                        }
                    },
                },
                {
                    title: 'Done',
                    width: 150,
                    render: (h, params) => {
                        return h(
                            'div',
                            (params.row.section ? params.row.section.name == 'Done' : '' && params.row.updatedAt)
                                ? moment(params.row.updatedAt).format('DD-MMM-YYYY')
                                : ''
                        );
                    },
                },
                {
                    title: 'Actions',
                    align: 'center',
                    width: 150,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-eye',
                                        size: '20',
                                        color: '#463C6E',
                                    },
                                    on: {
                                        click: () => {
                                            this.showAsanaTaskStories(params.row.gid);
                                        },
                                    },
                                },
                                'edit'
                            ),
                        ]);
                    },
                },
            ],
            trelloTblColumns: [
                {
                    title: 'Card #',
                    key: 'card_id',
                    width: 200,
                },
                {
                    title: 'Card Title',
                    key: 'card_name',
                    minWidth: 300,
                },
                {
                    title: 'Due Date',
                    width: 150,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.trelloCardDetails.due != null
                                ? moment(params.row.trelloCardDetails.due).format('DD-MMM-YYYY')
                                : ''
                        );
                    },
                },
                {
                    title: 'Status',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.trelloListDetails ? params.row.trelloListDetails.name : '');
                    },
                },
                {
                    title: 'Done',
                    width: 150,
                    render: (h, params) => {
                        const boardName = params.row.trelloListDetails
                            ? params.row.trelloListDetails.name.toLowerCase()
                            : '';
                        return h(
                            'div',
                            boardName.includes('Done'.toLowerCase()) && params.row.updatedAt
                                ? moment(params.row.updatedAt).format('DD-MMM-YYYY')
                                : ''
                        );
                    },
                },
            ],
            jiraTblColumns: [
                {
                    title: 'Issue #',
                    width: 100,
                    render: (h, params) => {
                        return h('div', params.row.issueDetails ? params.row.issueDetails.key : '');
                    },
                },
                {
                    title: 'Name',
                    minWidth: 330,
                    render: (h, params) => {
                        return h('div', params.row.issueDetails ? params.row.issueDetails.name : '');
                    },
                },
                {
                    title: 'UpdatedAt',
                    key: 'updatedAt',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.updatedAt ? moment(params.row.updatedAt).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'Status',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.issueDetails ? params.row.issueDetails.status.name : '');
                    },
                },
            ],
            tasks: [],
            selectedTask: 'asana',
        };
    },
    async mounted() {
        if (this.project.asana.length) {
            this.selectedTask = 'asana';
            this.tableColumns = this.asanaTblColumns;
        } else if (this.project.trello.length) {
            this.selectedTask = 'trello';
            this.tableColumns = this.trelloTblColumns;
        } else {
            this.selectedTask = 'jira';
            this.tableColumns = this.jiraTblColumns;
        }
        this.handleGetTasks();
    },
    methods: {
        async handleGetTasks() {
            if (this.project.asana && this.project.asana.length) {
                this.selectedTask = 'asana';
                const asanaTasks = await this.project.asana.map(async (data) => {
                    const tasks = await this.handleGetAsanaTasksByProject(data.id, this.page);
                    return {
                        ...data,
                        tasks,
                    };
                });
                this.tasks = await Promise.all(asanaTasks);
            } else if (this.project.trello && this.project.trello.length) {
                this.selectedTask = 'trello';
                const trelloTasks = await this.project.trello.map(async (data) => {
                    const tasks = await this.handleGetTrelloTasksByProject(data.id, this.page);
                    return {
                        ...data,
                        tasks,
                    };
                });
                this.tasks = await Promise.all(trelloTasks);
            } else if (this.project.jira && this.project.jira.length) {
                this.selectedTask = 'jira';
                const jiraTasks = await this.project.jira.map(async (data) => {
                    const tasks = await this.handleGetJiraTasksByProject(data.id, this.page);
                    return {
                        ...data,
                        label: data.key,
                        tasks,
                    };
                });
                this.tasks = await Promise.all(jiraTasks);
            }
        },
        handleGetAsanaTasksByProject(taskId, page) {
            return getPaginatiedAsanaTasks(
                this.project._id,
                { 'section.name': { $not: { $regex: 'info', $options: 'i' } } },
                page,
                10
            )
                .then((res) => {
                    return {
                        data: res.data.data,
                        currentPage: res.data.page,
                        totalCount: res.data.totalCount,
                        ongoing: res.data.sectionDetails,
                    };
                })
                .catch(() => {
                    this.$Message.error('Failed to get asana tasks list');
                });
        },
        handleGetTrelloTasksByProject(taskId, page) {
            return getTrelloCardsListById(
                this.project._id,
                {
                    'trelloBoardDetails.id': taskId,
                    'trelloCardDetails.closed': false,
                    'trelloListDetails.name': { $not: { $regex: 'info', $options: 'i' } },
                },
                page
            )
                .then((res) => {
                    return {
                        data: res.data.data,
                        currentPage: res.data.page,
                        totalCount: res.data.totalCount,
                    };
                })
                .catch(() => {
                    this.$Message.error('Failed to get trello tasks list');
                });
        },
        handleGetJiraTasksByProject(taskId, page) {
            return getPaginatedJiraIssues(this.project._id, {}, page, 10)
                .then((res) => {
                    return {
                        data: res.data.data,
                        currentPage: res.data.page,
                        totalCount: res.data.totalCount,
                    };
                })
                .catch(() => {
                    this.$Message.error('Failed to get jira issue list');
                });
        },

        async handleOnpageChanged(page, taskId) {
            this.isLoading = true;
            const tasks = this.tasks.map(async (data) => {
                if (data.id == taskId) {
                    if (this.selectedTask == 'asana') {
                        const tasks = await this.handleGetAsanaTasksByProject(data.id, page);
                        return {
                            ...data,
                            tasks,
                            currentPage: page,
                        };
                    } else if (this.selectedTask == 'trello') {
                        const tasks = await this.handleGetTrelloTasksByProject(data.id, page);
                        return {
                            ...data,
                            tasks,
                            currentPage: page,
                        };
                    } else if (this.selectedTask == 'jira') {
                        const tasks = await this.handleGetJiraTasksByProject(data.id, page);
                        return {
                            ...data,
                            tasks,
                            label: data.key,
                            currentPage: page,
                        };
                    }
                } else {
                    if (this.selectedTask == 'asana') {
                        const tasks = await this.handleGetAsanaTasksByProject(data.id, data.currentPage);
                        return {
                            ...data,
                            tasks,
                        };
                    } else if (this.selectedTask == 'trello') {
                        const tasks = await this.handleGetTrelloTasksByProject(data.id, data.currentPage);
                        return {
                            ...data,
                            tasks,
                        };
                    } else if (this.selectedTask == 'jira') {
                        const tasks = await this.handleGetJiraTasksByProject(data.id, data.currentPage);
                        return {
                            ...data,
                            label: data.key,
                            tasks,
                        };
                    }
                }
            });
            this.tasks = await Promise.all(tasks);
            this.isLoading = false;
        },

        handleGetAsanaTaskStories(taskId) {
            return getAsanaTaskStories(taskId)
                .then((res) => {
                    return res.data;
                })
                .catch(() => {
                    this.$Message.error('Failed to get asana task stories');
                });
        },

        async showAsanaTaskStories(taskId) {
            const stories = await this.handleGetAsanaTaskStories(taskId);
            if (stories.length) {
                this.$refs.TasksStoriesModal.openModal(stories);
            } else {
                this.$Message.info('No stories yet');
            }
        },

        async handleOnChangeTask(name) {
            this.selectedTask = name;
            if (name == 'asana') {
                this.tableColumns = this.asanaTblColumns;
                const asanaTasks = await this.project.asana.map(async (data) => {
                    const tasks = await this.handleGetAsanaTasksByProject(data.id, this.page);
                    return {
                        ...data,
                        tasks,
                    };
                });
                this.tasks = await Promise.all(asanaTasks);
            } else if (name == 'trello') {
                this.tableColumns = this.trelloTblColumns;
                const trelloTasks = await this.project.trello.map(async (data) => {
                    const tasks = await this.handleGetTrelloTasksByProject(data.id, this.page);
                    return {
                        ...data,
                        tasks,
                    };
                });
                this.tasks = await Promise.all(trelloTasks);
            } else if (name == 'jira') {
                this.tableColumns = this.jiraTblColumns;
                const jiraTasks = await this.project.jira.map(async (data) => {
                    const tasks = await this.handleGetJiraTasksByProject(data.id, this.page);
                    return {
                        ...data,
                        label: data.key,
                        tasks,
                    };
                });
                this.tasks = await Promise.all(jiraTasks);
            }
        },
    },
};
</script>
