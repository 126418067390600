<template>
    <div class="git-logs-wrapper">
        <div v-if="slacks && slacks.length">
            <div v-for="(s, idx) in slacks" :key="idx">
                <Card class="section-title">
                    {{ s.label }}
                </Card>
                <Table
                    :columns="tblColumns"
                    :data="s.messagesList ? s.messagesList.data : s.messagesList"
                    size="small"
                    :loading="isLoading"
                    height="450"
                />
                <Page
                    v-if="s.messagesList ? s.messagesList.totalCount > 10 : s.messagesList"
                    :total="s.messagesList ? s.messagesList.totalCount : s.messagesList"
                    :current="s.messagesList.currentPage"
                    :page-size="10"
                    class="pagination md-margin-bottom"
                    @on-change="handleOnpageChanged($event, s.label)"
                />
            </div>
        </div>
        <p v-else class="text-center">No Data</p>
    </div>
</template>

<script>
import moment from 'moment';
// import { getSlackMessagesByChannel } from '@/services/slack.services'
import { getSlackProjectMessages } from '../../services/slack.services';

export default {
    props: {
        slacks: {
            type: Array,
            default: () => [],
        },
        project: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            page: 1,
            totalCount: null,
            isLoading: false,
            tblColumns: [
                {
                    title: 'Author',
                    key: 'author',
                    minWidth: 200,
                    render: (h, params) => {
                        return h('div', params?.row?.user?.name || '');
                    },
                },
                {
                    title: 'Channel',
                    key: 'message',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params?.row?.channelDetails?.name || '');
                    },
                },
                {
                    title: 'Message',
                    key: 'message',
                    minWidth: 250,
                    render: (h, params) => {
                        return h('div', params?.row?.message || '');
                    },
                },
                {
                    title: 'Timestamp',
                    key: 'createdAt',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.createdAt ? moment(params.row.createdAt).format('DD-MMM-YYYY') : '');
                    },
                },
            ],
            tblData: [],
        };
    },
    async mounted() {
        this.isLoading = true;
        if (this.slacks.length) {
            const slacks = await this.slacks.map(async (slack) => {
                const messagesList = await this.handleGetSlackMessages(slack.id);
                return {
                    ...slack,
                    messagesList,
                };
            });
            this.slacks = await Promise.all(slacks);
        } else {
            this.slacks = [];
        }
        this.isLoading = false;
    },

    methods: {
        handleGetSlackMessages(channelId, page, totalCount) {
            return getSlackProjectMessages({
                filter: { project: this.project._id, 'channelDetails.id': channelId },
                page,
                totalCount,
            })
                .then((res) => {
                    let tempData = {
                        data: res.data.data,
                        totalCount: res.data.totalCount,
                        currentPage: res.data.page,
                    };
                    return tempData;
                })
                .catch(() => {
                    this.$Message.error('Failed to get slack messages');
                });
        },

        async handleOnpageChanged(page, label) {
            this.isLoading = true;
            const slacks = await this.slacks.map(async (slack) => {
                if (slack.label == label) {
                    const messagesList = await this.handleGetSlackMessages(slack.id, page);
                    return {
                        ...slack,
                        messagesList,
                        currentPage: page,
                    };
                } else {
                    const messagesList = await this.handleGetSlackMessages(slack.id, slack.currentPage);
                    return {
                        ...slack,
                        messagesList,
                    };
                }
            });
            this.slacks = await Promise.all(slacks);
            this.isLoading = false;
        },
    },
};
</script>
