<template>
    <div class="email-details-modal-wrapper">
        <Button class="pt-btn-2 md-margin-bottom" icon="md-return-left" size="small"> Back to Emails </Button>
        <Details />
        <Message class="md-margin-top" />
        <Tasks class="md-margin-top" />
        <TimeChart class="md-margin-top" />
        <Card class="pt-custom-card md-margin-top">
            <p slot="title">THANKS FOR WORKING WITH US!</p>
            <p>
                Access to
                <span style="background: #e9495d; padding: 5px 10px; border-radius: 3px; color: #ffffff"
                    >Project Tracker</span
                >
            </p>
            <div class="text-center">
                <Icon :size="25" type="logo-facebook" />
                <Icon :size="25" type="logo-instagram" />
                <Icon :size="25" type="logo-twitter" />
                <Icon :size="25" type="logo-youtube" />
            </div>
        </Card>
    </div>
</template>

<script>
import Details from '@/components/Logs/Emails/Details';
import Message from '@/components/Logs/Emails/Message';
import Tasks from '@/components/Logs/Emails/Tasks';
import TimeChart from '@/components/Logs/Emails/TimeChart';

export default {
    components: {
        Details,
        Message,
        Tasks,
        TimeChart,
    },
};
</script>

<style>
/* .email-details-modal-wrapper {
  width: 80%;
  margin: auto
} */
</style>
