<template>
    <Card class="pt-custom-card">
        <p slot="title">PM TOOL > DONE TASKS</p>
        <Table :columns="tblColumns" :data="tblData" height="200" size="small" />
    </Card>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            tblColumns: [
                {
                    title: 'Tasks Title',
                    key: 'name',
                },
                {
                    title: 'Due Date',
                    key: 'date',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.date ? moment(params.row.date).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: '# Hours',
                    key: 'hours',
                    align: 'center',
                    width: 90,
                },
                {
                    title: 'On-going',
                    key: 'date',
                    width: 120,
                    render: (h, params) => {
                        return h('div', params.row.date ? moment(params.row.date).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'Done',
                    key: 'date',
                    width: 120,
                    render: (h, params) => {
                        return h('div', params.row.date ? moment(params.row.date).format('DD-MMM-YYYY') : '');
                    },
                },
            ],
            tblData: [
                {
                    name: 'Admin > Timetracker > Add timelog to a specific member',
                    hours: 45,
                    date: '2022-03-11T01:00:00.000Z',
                },
                {
                    name: 'Admin > Timetracker > Add timelog to a specific member',
                    hours: 45,
                    date: '2022-03-11T01:00:00.000Z',
                },
                {
                    name: 'Admin > Timetracker > Add timelog to a specific member',
                    hours: 45,
                    date: '2022-03-11T01:00:00.000Z',
                },
                {
                    name: 'Admin > Timetracker > Add timelog to a specific member',
                    hours: 45,
                    date: '2022-03-11T01:00:00.000Z',
                },
            ],
        };
    },
};
</script>

<style></style>
