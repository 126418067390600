<template>
    <Card class="pt-custom-card">
        <p slot="title">TIME TRACKER</p>
        <TrackerBarGraph
            style="height: 300px; position: relative"
            :chartdata-sets="chartData"
            :chartdata-labels="chartDataLabels"
            :max-ticks="chartDataMaxTicks"
        />
    </Card>
</template>

<script>
import TrackerBarGraph from '@/components/Graphs/BarChart.js';

export default {
    components: {
        TrackerBarGraph,
    },
    data() {
        return {
            chartData: [
                {
                    label: 'Project 1',
                    backgroundColor: '#49D0E9',
                    data: [1, 5, 3],
                },
                {
                    label: 'Project 2',
                    backgroundColor: '#49E9BE',
                    data: [3, 4, 5],
                },
            ],
            chartDataLabels: ['12/01', '12/02', '12/03'],
            chartDataMaxTicks: 10,
        };
    },
};
</script>

<style></style>
